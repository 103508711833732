import React, { useLayoutEffect, useState } from "react"
import FormQuantumTouchRegister from "../../components/formQuantumTouchRegister"
import Layout from "../../components/layout"
import {
    Section,
    SectionHeading,
    SectionLedeDiv
  } from "../../styledComponents/section"

  const Heading = ({ level = 1, location, children, align }) => (
    <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
      {children}
    </SectionHeading>
  )

const QuantumTouchRegistration = ({ data, location }) => {
    const [eventType , setEventType] = useState({})
    useLayoutEffect(() => {
      if (typeof window !== "undefined" || window.document) {
        const urlParams = new URLSearchParams(window.location.search)
        const eventType = urlParams.get('eventType')
        setEventType(eventType)
        return
      }
    }, [])

    return (
      <>
      <Layout displayNavBorder location={location}>
        <Section>
          <Heading level={1} align="left">
            Quantum-Touch Registration
          </Heading>
          <SectionLedeDiv>
            <p>Please can you fill in the following form, this is required from the Quantum-Touch head office.</p>
          </SectionLedeDiv>
          <FormQuantumTouchRegister eventType={eventType} />
        </Section>
      </Layout>
      </>
    )
  }
  
  export default QuantumTouchRegistration